#arrowDown {
    top: -4.5em;
    left: 50%;
    color: black;

    .arrow {
        transform: translate(-50%,-50%);

        span {
            width: 30px;
            height: 30px;
            border-bottom: 5px solid white;
            border-right: 5px solid white;
            transform: rotate(45deg);
            margin: -10px;
            animation: animate 2s infinite;

            &:nth-child(2){
                animation-delay: -0.2s;
            }

            &:nth-child(3){
                animation-delay: -0.4s;
            }
        }
    }
}    

@include arrow-animation();