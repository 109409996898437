// Mixins
@mixin arrow-animation() {
  @keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
  }
}

@mixin column-count($number) {
  -webkit-column-count: $number;
  -moz-column-count: $number;
  column-count: $number;
}

@mixin column-gap($number) {
  -webkit-column-gap: $number * 1%;
  -moz-column-gap: $number * 1%;
  column-gap: $number * 1%;
}

@mixin columnSizing($id) {
  @include media-breakpoint-up(lg) {
    ##{$id}Col {
      @include column-count(3);
      @include column-gap(1);

      &:first-of-type {
        @include column-count(3);
        @include column-gap(3);
      }
    }
  }

  @include media-breakpoint-down(md) {
    ##{$id} {
      justify-content: space-around;
    }

    ##{$id}Col {
      @include column-count(2);
    }
  }

  @include media-breakpoint-down(sm) {
    ##{$id} {
      flex-direction: column;
    }

    ##{$id}Col {
      @include column-count(1);
    }
  }
}

@mixin serif-font() {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
}

@mixin sans-serif-font() {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

@mixin aileron-font() {
  font-family: 'Aileron';
}