// Global styling for this template
body,
html {
    width: 100%;
    height: 100%;
    margin: 0px;
    text-align: center;
}

section {
    padding: 50px 0;
    // position: relative;
}

h1, h2, h3, h4, h5, h6 {
    @include serif-font();
}

// Typography
.text-white-75 {
    color: fade-out($white, .25);
}

.text-black-75 {
    color: fade-out(black, .25);
}

hr {
    max-width: 90%;
    opacity: 1;
}

.section-orange {
    background-color: $orange;
    color: white;

    hr {
        border-color: white;
    }
}

.section-white {
    background-color: white;
    color: black;

    hr {
        border-color: black;
    }
}

.section-black {
    background-color: black;
    color: white;
}

/* Information/Location Section CSS*/
#informationCol, #locationCol {
    p {
        margin-left: 10px;
        margin-right: 10px;
        text-align: justify;
    }
}

@include columnSizing(information);
@include columnSizing(location);

// .carousel-caption h5,p {
//     color: black
// }