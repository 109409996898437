// Styling for the services section
#services {
	p {
		width: 90%;
	}

	.service-box {
		.service-box-caption {
			opacity: 0;
			color: white;
			background: fade-out($primary, .1);
			transition: opacity .25s ease;

			.project-category {
				font-family: $font-family-sans-serif;
				font-size: .85rem;
				font-weight: 600;
				text-transform: uppercase;
			}

			.project-name {
				font-size: 1.2rem;
			}

		}
		&:hover {
			.service-box-caption {
				opacity: 1;
			}
		}
	}
}