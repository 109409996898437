// Styling for the masthead
header.masthead {
    // padding-top: 10rem;
    // padding-bottom: calc(10rem - #{$navbar-height});
    background-image: url('/build/imgs/main/corby-overview-optimized.png?as=avif&quality=100&width=1600&height=900');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    h1 {
        font-size: 2.25rem;
        font-weight: 700;
        color: $gold;
    }

    p {
        font-weight: 300;
        // to keep it shorter/box like
        max-width: 60%;
        color: fade-out(white, .2);
    }

    .header-content {
        height: calc(100vh - #{$navbar-height} - 10rem);
        padding: 150px 15px 100px;

        &-inner {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    @include media-breakpoint-down(md) {
        .header-content {
            height: calc(100vh - #{$navbar-height} - 10rem);
            &-inner {
                padding-top: 10rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        height: 100vh;
        min-height: 40rem;
        padding-top: $navbar-height;
        padding-bottom: 0;

        p {
            font-size: 1.15rem;
        }

        h1 {
            font-size: 3rem;
        }

        .header-content-inner {
            padding-top: 32rem;
        }
    }

    @include media-breakpoint-up(xl) {
        h1 {
            font-size: 4.0rem;
        }
    }
}
