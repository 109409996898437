// Variables

// Color overrides

// Set a custom color
$orange: #f05f40 !default;
$brown: #5c4d42 !default;
$blue: #0004ff;
$blue-darker: #04003d;
$gold: #fac108;

// Set primary color to orange
$primary: $orange !default;

// Font overrides
$font-family-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$font-family-serif: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$font-family-base: $font-family-serif !default;

$headings-font-family: $font-family-sans-serif !default;

$font_family_aileron: 'Aileron';
// Custom variables
$navbar-height: 56px;